<template>
  <div id="nav">
    <router-link to="/">Accueil</router-link> |
    <router-link to="/addition-statique-systeme-decimal">Addition Statique</router-link> |
    <router-link to="/addition-dynamique-systeme-decimal">Addition Dynamique</router-link> |
    <router-link to="/soustraction-statique-systeme-decimal">Soustraction Statique</router-link> |
    <router-link to="/soustraction-dynamique-systeme-decimal">Soustraction Dynamique</router-link>
    <br>
    <router-link to="/vie-sensorielle/boite-des-couleurs-1">Boite des couleurs 1</router-link> |
    <router-link to="/vie-sensorielle/boite-des-couleurs-2">Boite des couleurs 2</router-link> |
    <router-link to="/vie-sensorielle/boite-des-couleurs-3">Boite des couleurs 3</router-link>
    <br>
    <router-link to="/vie-sensorielle/les-clochettes">Les clochettes</router-link> |
    <router-link to="/vie-sensorielle/les-boites-a-sons">Les boîtes à sons</router-link> |
    <router-link to="/vie-sensorielle/les-barres-rouges">Les barres rouges</router-link> |
    <router-link to="/vie-sensorielle/le-cube-du-binome">Le cube du binôme</router-link>
    <br>
    <router-link to="/vie-sensorielle/la-tour-rose">La tour rose</router-link> |
    <router-link to="/vie-sensorielle/l-escalier-marron">L'escalier marron</router-link> |
    <router-link to="/vie-sensorielle/les-triangles-constructeurs-1">Les triangles constructeurs 1</router-link>
    <br>
    <router-link to="/vie-sensorielle/botanique-1">Botanique 1</router-link> |
    <router-link to="/vie-sensorielle/botanique-2">Botanique 2</router-link> |
    <router-link to="/vie-sensorielle/botanique-3">Botanique 3</router-link>
    <br>
    <router-link to="/vie-sensorielle/les-formes-superposees">Les formes superposées</router-link> |
    <router-link to="/vie-sensorielle/la-table-de-pythagore">La table de Pythagore</router-link>
  </div>


  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
