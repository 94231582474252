<template>
  <div class="home">
      <a href="https://app.formationmontessori.fr/pages/accueil">
          <img class="width_500" alt="Vue logo" src="../assets/logo1.png">
      </a>
    <br>
    <a href="https://ief-montessori.com/methode-montessori">
        <img class="width_300" alt="Vue logo" src="../assets/logo.png">
    </a>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style media="screen">
    .width_500{
        width: 500px;
    }
    .width_300{
        width: 300px;
    }
</style>
