import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/addition-statique-systeme-decimal',
    name: 'Addition Statique',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdditionStatique.vue')
  },
  {
    path: '/addition-dynamique-systeme-decimal',
    name: 'Addition Dynamique',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdditionDynamique.vue')
  },
  {
    path: '/soustraction-statique-systeme-decimal',
    name: 'Soustraction Statique',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SoustractionStatique.vue')
  },
  {
    path: '/soustraction-dynamique-systeme-decimal',
    name: 'Soustraction Dynamique',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SoustractionDynamique.vue')
  },
  {
    path: '/vie-sensorielle/boite-des-couleurs-1',
    name: 'La 1ère boîte des couleurs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/boites-des-couleurs/BoiteDesCouleurs1.vue')
  },
  {
    path: '/vie-sensorielle/boite-des-couleurs-2',
    name: 'La 2ème boîte des couleurs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/boites-des-couleurs/BoiteDesCouleurs2.vue')
  },
  {
    path: '/vie-sensorielle/boite-des-couleurs-3',
    name: 'La 3ème boîte des couleurs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/boites-des-couleurs/BoiteDesCouleurs3.vue')
  },
  {
    path: '/vie-sensorielle/les-clochettes',
    name: 'Les clochettes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/clochettes/Clochettes.vue')
  },
  {
    path: '/vie-sensorielle/les-boites-a-sons',
    name: 'Les boîtes à sons',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/la-boite-a-son/BoiteASon.vue')
  },
  {
    path: '/vie-sensorielle/les-barres-rouges',
    name: 'Les barres rouges',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/les-barres-rouges/BarresRouges.vue')
  },
  {
    path: '/vie-sensorielle/le-cube-du-binome',
    name: 'Le cube du binôme',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/le-cube-du-binome/CubeBinome.vue')
  },
  {
    path: '/vie-sensorielle/la-tour-rose',
    name: 'La tour rose',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/la-tour-rose/TourRose.vue')
  },
  {
    path: '/vie-sensorielle/l-escalier-marron',
    name: "L'escalier marron",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/l-escalier-marron/EscalierMarron.vue')
  },
  {
    path: '/vie-sensorielle/les-triangles-constructeurs-1',
    name: "Les triangles constructeurs 1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/les-triangles-constructeurs-1/TrianglesConstructeurs.vue')
  },
  {
    path: '/vie-sensorielle/botanique-1',
    name: "Botanique 1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/botanique-1/Botanique1.vue')
  },
  {
    path: '/vie-sensorielle/botanique-2',
    name: "Botanique 2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/botanique-2/Botanique2.vue')
  },
  {
    path: '/vie-sensorielle/botanique-3',
    name: "Botanique 3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/botanique-3/Botanique3.vue')
  },
  {
    path: '/vie-sensorielle/les-formes-superposees',
    name: "Les formes superposées",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/formes-superposees/FormesSuperposees.vue')
  },
  {
    path: '/mathematiques-12-15/calcul-litteral',
    name: "Le calcul litteral",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/mathematiques-12-15/CalculLitteral.vue')
  },
  {
    path: '/vie-sensorielle/la-table-de-pythagore',
    name: "La table de Pythagore",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/table-de-pythagore/TablePythagore.vue')
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
